<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      offset-x
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          small
          v-bind="attrs"
          v-on="on"
          color="grey"
          :disabled="disable"
      >
        mdi-calendar
      </v-icon>
    </template>
    <v-date-picker
        v-model="date"
        :type="monthly ? 'month' : 'date'"
        no-title
        scrollable
        :first-day-of-week="1"
        color="secondary"
        min="1910-01-01"
        locale="uk-UA"
    >
      <v-spacer/>
      <v-btn
          depressed text tile color="grey"
          @click="menu = false"
      >
        Відмінити
      </v-btn>
      <v-btn
          depressed text
          color="secondary darken-1"
          @click="saveDate"
      >
        <v-icon small>mdi-calendar-check-outline</v-icon>
        ОК
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    row_num: {
      type: Number,
      default: 0
    },
    monthly: {
      type: Boolean,
      default() {
        return false
      }
    },
    disable: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  name: "DateComponentIcon",
  data() {
    return {
      date: this.value,
      menu: false
    }
  },
  methods: {
    saveDate() {
      let date = this.date ? this.monthly ? this.date + '-01' : this.date : this.date
      this.$refs.menu.save(this.date)
      this.$emit('changeDate', { value: date, row_num: this.row_num })
    }
  },
  computed: {
  },
  watch: {
    date(value) {
      this.date = value
    },
    value() {
      this.date = this.value
    }
  },
}
</script>

<style scoped>

</style>